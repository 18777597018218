//import './accordion'
//import './pricing'
import './heroslid.js'
import Splide from '../../_snowpack/pkg/@splidejs/splide.js'

const $all = (selector, context) => (context || document).querySelectorAll(selector);

const $ = (selector, context) => (context || document).querySelector(selector);

//*
// document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
//


if ($('.splide') != null) {
    new Splide( '.splide' , {
        type   : 'loop',
        perPage: 3,
    } ).mount()
}
if ($('.splideFull') != null) {
    new Splide( '.splideFull' , {
        perPage: 1,
    } ).mount()
}
console.log(window.devicePixelRatio)
